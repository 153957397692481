<template>
    <ul :class="reactiveClass" class="category-access-list">
        <li @click="selectCateg(categ)" :class="populateClass(categ)" v-for="(categ, index) in categList" :key="index">
            <div>
                <span @click="requestCategory(categ)" :class="{ 'can-get-access': canRequestAccess(categ) }">
                    <svg-icon :class="getIconClass(categ, true)" :icon-class="getIconClass(categ)" />
                </span>
                <span :class="{ 'can-get-access': hasChildren(categ) }" class="categName">
                    {{ categ.categories_name }}
                </span>
            </div>
            <div class="commission">
                <div
                    class="comm"
                    :class="{
                        'default-commission': !hasAccessCommission(categ),
                        'access-commission': hasAccessCommission(categ),
                    }"
                >
                    <div>
                        <i class="el-icon-s-flag"></i>
                    </div>
                    <div>
                        {{ categ.commission || 'N/A' }}
                    </div>
                </div>
                <i class="el-icon-arrow-right" :class="{ isVisible: hasChildren(categ) }"> </i>
            </div>
        </li>
    </ul>
</template>

<script>
import { EventBus } from '@/utils/Bus.js';
export default {
    props: ['catLevel', 'catList', 'selected'],
    data() {
        return {};
    },
    methods: {
        selectCateg(categ) {
            if (!this.hasChildren(categ)) return false;
            EventBus.$emit('category-selected', {
                id: categ.categories_id,
                nextLevel: this.categoryLevel + 1,
            });
        },
        populateClass(categ) {
            return {
                'categ-line': true,
                'hvr-fade': typeof categ.hasChildren !== 'undefined' && parseInt(categ.hasChildren) === 1,
                selected: this.selectedReactive.includes(categ.categories_id),
            };
        },
        getIconClass(categ, colorOnly = false) {
            if (colorOnly) {
                switch (true) {
                    case typeof categ.isInaccessible !== 'undefined' &&
                        parseInt(categ.isInaccessible) === 1 &&
                        (typeof categ.hasAccess === 'undefined' || categ.hasAccess == 0):
                        return 'red';

                    case typeof categ.hasAccess !== 'undefined' && categ.hasAccess === 1:
                        return 'warning';

                    case typeof categ.hasAccess !== 'undefined' && categ.hasAccess === 2:
                        return 'green';

                    case typeof categ.accessRequest !== 'undefined' && parseInt(categ.accessRequest) === 3:
                        return 'danger';

                    case typeof categ.accessRequest !== 'undefined' && parseInt(categ.accessRequest) > 0:
                        return 'warning';

                    default:
                        return 'blue';
                }
            } else {
                switch (true) {
                    case typeof categ.isInaccessible !== 'undefined' &&
                        parseInt(categ.isInaccessible) === 1 &&
                        (typeof categ.hasAccess === 'undefined' || categ.hasAccess == 0):
                        return 'cancel';

                    case typeof categ.hasAccess !== 'undefined' && categ.hasAccess === 1:
                        return 'file';

                    case typeof categ.hasAccess !== 'undefined' && categ.hasAccess === 2:
                        return 'checkmark';

                    case typeof categ.accessRequest !== 'undefined' && parseInt(categ.accessRequest) === 3:
                        return 'minus';

                    case typeof categ.accessRequest !== 'undefined' && parseInt(categ.accessRequest) > 0:
                        return 'hourglass';

                    default:
                        return 'unlock';
                }
            }
        },
        hasChildren(categ) {
            return typeof categ.hasChildren !== 'undefined' && parseInt(categ.hasChildren) === 1;
        },
        canRequestAccess(categ) {
            return (
                typeof categ.isInaccessible !== 'undefined' &&
                parseInt(categ.isInaccessible) === 0 &&
                categ.accessRequest === 0 &&
                typeof categ.hasAccess !== 'undefined' &&
                parseInt(categ.hasAccess) === 0
            );
        },
        requestCategory(categ) {
            if (!this.canRequestAccess(categ)) return false;
            EventBus.$emit('request-category-access', categ);
        },
        hasAccessCommission(categ) {
            return typeof categ.commission !== 'undefined' && categ.hasAccess >= 1;
        },
    },
    computed: {
        categList() {
            return this.catList;
        },
        categoryLevel() {
            return this.catLevel;
        },
        reactiveClass() {
            return [`list-${this.categoryLevel}`];
        },
        selectedReactive() {
            return this.selected || [];
        },
    },
    watch: {},
    created() {},
    destroyed() {},
};
</script>
<style rel="stylesheet/scss" lang="scss">
.hvr-fade {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}

.hvr-fade:hover,
.hvr-fade:focus,
.hvr-fade:active {
    background-color: #f5f5f5;
}

.category-access-list {
    list-style: none;
    padding: 0;
    margin-right: 20px;

    li:not(.noChilds) .categName.can-get-access {
        cursor: pointer;
    }

    li .can-get-access {
        cursor: pointer;
    }

    li {
        padding: 5px 0;
    }

    li .svg-icon {
        margin: 0 5px;
    }

    li.selected {
        background-color: #efefef;
    }

    i.el-icon-arrow-right {
        float: right;
        padding-right: 5px;
    }

    .svg-icon.red {
        color: #909399;
    }

    .svg-icon.green {
        color: #67c23a;
    }

    .svg-icon.blue {
        color: #409eff;
    }

    .svg-icon.warning {
        color: #e6a23c;
    }

    .svg-icon.danger {
        color: #f56c6c;
    }
    .categ-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        line-height: 1.4;
    }
    .commission {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-size: 12px;

        i.el-icon-s-flag {
            font-size: 14px;
        }

        .comm {
            display: flex;
            justify-content: start;
            align-items: center;
            cursor: pointer;
            gap: 3px;

            &.default-commission i.el-icon-s-flag {
                color: #ff601d;
            }

            &.access-commission i.el-icon-s-flag {
                color: #67c23a;
            }
        }
    }

    i.el-icon-arrow-right {
        visibility: hidden;
        cursor: pointer;
    }
    .isVisible {
        visibility: visible !important;
    }
}
</style>
